import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import authService from '@/services/auth';
import Cookies from 'js-cookie';

const adminRoutes = [].map((item) => {
  item.path = '/admin' + item.path;
  item.beforeEnter = (to, from, next) => {
    if (!useAuthStore().user?.isAdmin) next('/');
    next();
  };
  return item;
});
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue'),
    },
    {
      path: '/sem-permissao',
      name: 'sem-permissao',
      component: () => import('../views/SemPermissaoView.vue'),
    },
    {
      path: '/',
      name: 'pesquisar-solicitacoes',
      component: () => import('../views/PesquisarSolicitacoesView.vue'),
    },
    {
      path: '/acervo-recolhido',
      name: 'pesquisar-solicitacoes-acervo-recolhido',
      component: () => import('../views/PesquisarSolicitacoesAcervoRecolhidoView.vue'),
    },
    {
      path: '/solicitacoes',
      name: 'solicitacoes',
      component: () => import('../views/SolicitacoesView.vue'),
    },
    {
      path: '/solicitacoes-acervo-recolhido',
      name: 'solicitacoes-acervo-recolhido',
      component: () => import('../views/SolicitacoesAcervoRecolhidoView.vue'),
    },
    {
      path: '/prestacao-contas',
      name: 'prestacao-contas',
      component: () => import('../views/PrestacaoContasView.vue'),
    },
    {
      path: '/editar-cota/:id',
      name: 'editar-cota',
      component: () => import('../views/EditarCotaView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/editar-cota-acervo-recolhido/:id',
      name: 'editar-cota-acervo-recolhido',
      component: () => import('../views/EditarCotaAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/editar-cota-complementar/:id',
      name: 'editar-cota-complementar',
      component: () => import('../views/EditarCotaComplementarView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/editar-cota-complementar-acervo-recolhido/:id',
      name: 'editar-cota-complementar-acervo-recolhido',
      component: () => import('../views/EditarCotaComplementarAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/complementar/:id',
      name: 'complementar',
      component: () => import('../views/PagamentoComplementarView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/complementar-acervo-recolhido/:id',
      name: 'complementar-acervo-recolhido',
      component: () => import('../views/PagamentoComplementarAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/obter-cota-complementar/:id',
      name: 'obter-cota-complementar',
      component: () => import('../views/CotaComplementarView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/obter-cota-complementar-acervo-recolhido/:id',
      name: 'obter-cota-complementar-acervo-recolhido',
      component: () => import('../views/CotaComplementarAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/boleto-sucesso',
      name: 'boleto-sucesso',
      component: () => import('../views/BoletoView.vue'),
    },
    {
      path: '/boleto-sucesso-acervo-recolhido',
      name: 'boleto-sucesso-acervo-recolhido',
      component: () => import('../views/BoletoAcervoRecolhidoView.vue'),
    },
    {
      path: '/nivel-baixo',
      name: 'nivel-baixo',
      component: () => import('../views/nivelDeGarantia/NivelBaixoView.vue'),
    },
    {
      path: '/nivel-substancial',
      name: 'nivel-substancial',
      component: () => import('../views/nivelDeGarantia/NivelSubstancialView.vue'),
    },
    {
      path: '/nivel-alto',
      name: 'nivel-alto',
      component: () => import('../views/nivelDeGarantia/NivelAltoView.vue'),
    },

    ...adminRoutes,
  ],
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/',
    '/bootstrap',
    '/sem-acesso',
    '/idrc',
    '/idrc-sucesso',
    '/solicitacao',
    '/login',
    '/rc',
    '/redeconsular',
  ];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();
  const { logout } = to.query;
  const userSession = Cookies.get('has_user_session')
    ? JSON.parse(atob(Cookies.get('has_user_session'))).message
    : null;

  if (logout) {
    await auth.logoutCallback();
    return '/';
  }

  if (auth.token || authRequired) {
    const authenticated = await auth.verifyLogin();

    if (!authenticated && authRequired) {
      auth.logoutCallback();
      return '/login';
    } else {
      if (to.path === '/login') {
        return '/';
      }
    }
    auth.user = await authService.getSelfData();
  } else {
    Cookies.remove('has_user_session');
    if (!(userSession === false) && to.path !== '/login') {
      await auth.login('substancial', 'none');
    }
  }
});

export default router;
