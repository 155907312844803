<script setup>
import $ from "jquery";
import { RouterView, useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { onMounted, watch, ref, computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useToast } from 'vue-toastification';
import helpers from '@/helpers';

// Constantes para funções permitidas
const FUNCOES_PERMITIDAS = ['Oficial', 'Substituto'];

// Rotas que não precisam de verificação de permissão
const ROTAS_PUBLICAS = ['/sem-permissao', '/login'];

const auth = useAuthStore();
const route = useRoute();
const router = useRouter();
const funcaoLocal = ref(null);
const funcao = ref('');
const funcaoCache = ref(null);
const isHeaderLoaded = ref(false);
const toast = useToast();

// Função de validação
const validarDadosCartorio = (data) => {
  if (!data || typeof data !== 'object') return false;
  const cartorio = data;
  return (
    typeof cartorio.funcao === 'string' &&
    FUNCOES_PERMITIDAS.includes(cartorio.funcao) &&
    typeof cartorio.nome === 'string' &&
    typeof cartorio.cns === 'string'
  );
};

// Função para obter dados do localStorage com cache
const getFuncao = () => {
  try {
    const cartorioData = localStorage.getItem('cartorio');
    if (!cartorioData) {
      return null;
    }

    const cartorio = JSON.parse(cartorioData);
    if (!validarDadosCartorio(cartorio)) {
      throw new Error('Dados do cartório inválidos');
    }

    return cartorio.funcao;
  } catch (error) {
    console.error('Erro ao obter função:', error);
    return null;
  }
};

// Função para limpar cache quando o cartório for alterado
const limparCache = () => {
  funcaoCache.value = null;
  funcao.value = '';
  funcaoLocal.value = null;
  localStorage.removeItem('endereco');
};

// Função para verificar primeiro acesso
const isFirstAccess = () => {
  return !localStorage.getItem('cartorio');
};

// Função única para verificação de função
const verificarFuncao = async () => {
  try {
    // Não verifica permissões em rotas públicas
    if (ROTAS_PUBLICAS.includes(route.path)) {
      return true;
    }

    // Se for primeiro acesso, permite continuar sem verificar permissões
    if (isFirstAccess()) {
      return true;
    }

    const funcaoAtual = getFuncao();
    
    if (!funcaoAtual) {
      await router.push('/sem-permissao');
      return false;
    }

    // Verifica se a função está na lista de funções permitidas
    if (!FUNCOES_PERMITIDAS.includes(funcaoAtual)) {
      await router.push('/sem-permissao');
      return false;
    }

    return true;
  } catch (error) {
    console.error('Erro ao verificar função:', error);
    // Se for primeiro acesso, não redireciona em caso de erro
    if (!isFirstAccess()) {
      await router.push('/sem-permissao');
      return false;
    }
    return true;
  }
};

// Guard de navegação global
const verificarPermissaoRota = async (to) => {
  if (ROTAS_PUBLICAS.includes(to.path)) {
    return true;
  }

  const temPermissao = await verificarFuncao();
  if (!temPermissao) {
    return false;
  }
  return true;
};

// Watch para mudanças na rota
watch(
  () => route.path,
  async (newPath, oldPath) => {
    if (newPath !== oldPath && !ROTAS_PUBLICAS.includes(newPath) && !isFirstAccess()) {
      await verificarFuncao();
    }
  }
);

// Hook de navegação
onBeforeRouteUpdate(async (to, from, next) => {
  const podeNavegar = await verificarPermissaoRota(to);
  if (podeNavegar) {
    next();
  } else {
    next(false);
  }
});

onMounted(async () => {
  await carregarHeaderFooter();
  
  // Verificação inicial de permissões
  if (!ROTAS_PUBLICAS.includes(route.path)) {
    await verificarFuncao();
  }

  // Configurar observer do header
  const header = document.getElementById('header');
  if (header) {
    observer.observe(header, { childList: true, subtree: true });
  }
});

// Função para troca de cartório
const trocarCartorio = async () => {
  try {
    // Guarda a rota atual
    const rotaAtual = route.path;

    // Obtém e valida o novo cartório
    const novoCartorioData = localStorage.getItem('cartorio');
    if (!novoCartorioData) {
      throw new Error('Nenhum cartório selecionado');
    }

    const novoCartorio = JSON.parse(novoCartorioData);
    if (!validarDadosCartorio(novoCartorio)) {
      throw new Error('Dados do cartório inválidos');
    }

    // Limpa todos os caches e estados
    limparCache();
    isHeaderLoaded.value = false;
    
    // Atualiza estados locais
    funcaoLocal.value = novoCartorio.funcao;
    funcao.value = novoCartorio.funcao;
    
    // Verifica permissões com o novo cartório
    const temPermissao = await verificarFuncao();
    if (!temPermissao) {
      return;
    }

    // Recarrega o header com os novos dados
    await carregarHeaderFooter();

    // Se estiver em uma rota protegida, faz a navegação suave
    if (!ROTAS_PUBLICAS.includes(rotaAtual)) {
      // Desativa temporariamente o observer para evitar chamadas duplicadas
      if (observer) {
        observer.disconnect();
      }

      // Navegação suave: vai para home e volta para a rota atual
      if (rotaAtual !== '/') {
        await router.push('/');
        // Pequeno delay para garantir que os estados foram limpos
        await new Promise(resolve => setTimeout(resolve, 100));
        await router.push(rotaAtual);
      } else {
        await router.push('/');
      }

      // Reativa o observer
      const header = document.getElementById('header');
      if (header && observer) {
        observer.observe(header, { childList: true, subtree: true });
      }
    }

    toast.success('Cartório alterado com sucesso!');
  } catch (error) {
    console.error('Erro ao trocar cartório:', error);
    toast.error('Erro ao trocar cartório. Por favor, tente novamente.');
    
    // Em caso de erro, tenta restaurar o estado
    try {
      await verificarFuncao();
    } catch (e) {
      console.error('Erro ao restaurar estado:', e);
    }
  }
};

const carregarHeaderFooter = async () => {
  if (isHeaderLoaded.value) {
    return;
  }

  const suporte = {
    telefone: '(11) 5555-9252',
    email: 'suporte.crc@registrocivil.org.br',
  };
  let script = document.createElement('script');
  script.setAttribute('type', 'module');
  script.innerHTML = `
        import {instalarHeaderFooter} from '${
          import.meta.env.VITE_URL_BASE_HEADER_FOOTER
        }/header-footer.min.js';
          instalarHeaderFooter({
              usuario: {
                  nome: '${auth.user?.name || ''}',
                  cpf: '${helpers.formatarCPF(auth.user?.cpf) || ''}',
                  foto: '${auth.user?.foto_de_perfil_url || ''}',
                  grupos: ${JSON.stringify(auth.user?.groups || [])}
              },
              urlLogin: '${auth.urlLoginScreen()}',
              urlLogout: '${auth.urlLogout()}',
              token: '${auth.getToken()}',
              cartorio: '${
                auth.user?.notaries && auth.user?.notaries?.length
                  ? auth.user?.notaries[0]?.nome
                  : ''
              }',
              exibirPerguntasFrequentes: ${true},
              exibirFaleConoscoPeloChat: ${true},
              suporte: '${JSON.stringify(suporte)}',
              exibirAcessoRemoto: ${true},
              exibirWhatsApp: ${true},
          });
      `;
  document.body.appendChild(script);

  // Substituir o evento do botão
  $('#botaoSelecionarCartorio').off('click').on('click', async function(e) {
    e.preventDefault();
    $('#screenLoading').show();
    await trocarCartorio();
    $('#screenLoading').hide();
  });

  // Modificar o listener de storage
  window.addEventListener('storage', async (e) => {
    if (e.key === 'cartorio') {
      $('#screenLoading').show();
      await trocarCartorio();
      $('#screenLoading').hide();
    }
  });

  // Verificar permissões inicialmente apenas se não estiver em rota pública
  // e não for primeiro acesso
  if (!ROTAS_PUBLICAS.includes(route.path) && !isFirstAccess()) {
    await verificarFuncao();
  }

  isHeaderLoaded.value = true;
};

// Observar mudanças no localStorage localmente
const observer = new MutationObserver(async (mutations) => {
  mutations.forEach(async (mutation) => {
    if (mutation.type === 'childList' && mutation.target.id === 'header') {
      if (!ROTAS_PUBLICAS.includes(route.path) && !isFirstAccess()) {
        limparCache();
        await verificarFuncao();
      }
    }
  });
});

// Modificar o watch para evitar loops
watch(
  () => auth.user,
  async (newValue, oldValue) => {
    if (newValue && (!oldValue || newValue.id !== oldValue.id)) {
      limparCache();
      isHeaderLoaded.value = false;
      await carregarHeaderFooter();
      // Verifica permissões após carregar header
      if (!ROTAS_PUBLICAS.includes(route.path)) {
        await verificarFuncao();
      }
    }
  },
  { deep: true }
);
</script>

<template>
  
  <div id="appContainer">
    
    <div id="header"></div>
    <div id="body" class="">
      <div class="container-fluid">
        <RouterView />
      </div>  
    </div>
    <div id="footer"></div>

  </div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'https://arpen-header-footer-homolog.codebit.biz/header-footer.css';
#appContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#header,
#body,
#body > div,
#footer {
  flex: 1;
}

#header,
#footer {
  display: contents;
}

#body {
  display: flex;

  > div {
    min-height: 640px;
    display: flex;
    justify-content: center;
    //padding: 64px 16px 32px;
    width: 100%;
  }
}
</style>
