const Helper = {
  formatarCPF(cpf) {
    if (!cpf) return;
    const cpfLimpo = cpf.replace(/\D/g, '');
    if (cpfLimpo.length === 11) {
      return (
        cpfLimpo.substring(0, 3) +
        '.' +
        cpfLimpo.substring(3, 6) +
        '.' +
        cpfLimpo.substring(6, 9) +
        '-' +
        cpfLimpo.substring(9)
      );
    }
  },
};

export default Helper;
