import { defineStore } from 'pinia';
import authService from '@/services/auth';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

let refreshTokenTimeout = null;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: Cookies.get('application-token')
      ? JSON.parse(atob(Cookies.get('application-token'))).message
      : null,
    user: null,
  }),
  actions: {
    // level (nível de garantia): baixo, substancial, alto
    async login(level = '', prompt = 'consent') {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
        level,
        prompt,
      });

      window.location.href = `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    async verifyLogin() {
      await this.verifyAndUpdateToken();
      return this.token;
    },
    logout() {
      window.location.href = this.urlLogout();
    },
    urlLoginScreen() {
      return `${import.meta.env.VITE_BASE_URL}/login`;
    },
    urlLogin(
      platform = import.meta.env.OIDC_CLIENT_ID ? import.meta.env.OIDC_CLIENT_ID : 'default',
      flow = 'password'
    ) {
      const params = new URLSearchParams({
        redirectUrl: `${import.meta.env.VITE_BASE_URL}`,
        flow,
      });

      return `${import.meta.env.VITE_API_URL}/login?platform=${platform}&${params.toString()}`;
    },
    urlLoginICP() {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
        flow: 'icp',
      });

      return `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    urlLogout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      return `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`;
    },
    logoutCallback() {
      this.token = null;
      this.usuario = null;
      Cookies.remove('application-token');
      localStorage.removeItem('cartorio');
    },
    updateToken() {
      this.token = jwt_decode(this.getToken());
    },
    async verifyAndUpdateToken() {
      try {
        await authService.verifyAndUpdateToken();
        this.token = jwt_decode(this.getToken());
        this.refreshToken();
        this.updateToken();
      } catch (e) {
        await this.logoutCallback();
      }
    },
    refreshToken() {
      clearTimeout(refreshTokenTimeout);
      const currentDate = new Date();
      const expiresAt = new Date(0);
      expiresAt.setUTCSeconds(this.token.expires_at);
      const executeAt = expiresAt - currentDate - 60;

      refreshTokenTimeout = setTimeout(async () => {
        await this.verifyAndUpdateToken();
      }, executeAt);
    },
    async ensureLOA(level) {
      if (level === 'substancial' && this.token.acr === 'urn:idrc:loa:baixo') {
        return this.login(level);
      }

      if (level === 'alto' && this.token.acr !== 'urn:idrc:loa:alto') {
        return this.login(level);
      }
    },
    getToken() {
      const token = Cookies.get('application-token');
      return token && JSON.parse(atob(token)).message;
    },
  },
});
